import {Component, Provide, Vue, Watch} from 'vue-property-decorator';
import {AddCartReq} from "@/interface/res";
import {ShoppingCartList} from "@/interface/pagas/common";

import {AddCartApi} from "@/network/modules";
import {mapGetters} from "vuex";
import {GetPreTimeByCartApi} from "@/network/modules/mine";
import {GetPreTimeByCartReq} from "@/interface/res/common";

@Component({
  components: {
  },
  computed: {
    ...mapGetters(["shoppingCartList","shoppingCartListTotal"])
  }
})
export default class Status extends Vue {
  @Provide() statusData = {};
  @Provide() params = {};
  @Provide() id:number = -1;
  @Provide() loading:boolean = false;
  @Provide() data:ShoppingCartList = {};
  @Provide() addType:number = 0;
  @Provide() preTimeByCart:string = '获取中...';

  @Watch('shoppingCartList',{immediate:false, deep:false})
  @Watch('statusData',{immediate:false, deep:false})
  private shoppingCartListChange():void {
    const _this:any = this;
    if(_this.$base.isNull(_this.loading))_this.loading = true
    if(_this.$base.isNull(_this.shoppingCartList))return
    if(!_this.$base.isNull(_this.statusData.id)){
      _this.id = _this.statusData.id
      _this.addType = 1;
      console.log('%c [ ------- ]: ', 'color: #bf2c9f; background: pink; font-size: 13px;', '-------2')
      // _this.data = _this.shoppingCartList.filter((item:any) => item.cartId == _this.id)[0]
      console.log(_this.shoppingCartList);
      console.log(_this.data);
      _this.preTimeByCart = _this.statusData.time
      let a = setTimeout(()=>{_this.loading = false;clearTimeout(a)},500)
      return
    }
    console.log('%c [ 444 ]: ', 'color: #bf2c9f; background: pink; font-size: 13px;', '444')
    console.log(_this.shoppingCartList);
    if(!_this.$base.isNull(_this.statusData.data)){
      _this.addType = 2
      console.log('%c [ ------- ]: ', 'color: #bf2c9f; background: pink; font-size: 13px;', '-------3')
      // _this.data = JSON.parse(_this.statusData.data)
      _this.preTimeByCart = _this.statusData.time
      let a = setTimeout(()=>{_this.loading = false;clearTimeout(a)},500)
      return
    }
    console.log(_this.data);
  }
  created(){
    const _this:any = this;
    let statusData = _this.$getDecryptBy('_STATUSDATA')
    if(statusData.indexOf('暂未存储') == -1){
      _this.statusData = JSON.parse(statusData)
      return;
    }
    console.log('%c [ 222 ]: ', 'color: #bf2c9f; background: pink; font-size: 13px;', _this.$route.query.p)
    if(!_this.$base.isNull(_this.$route.query.diy)){
      _this.params = _this.$decryptBy(_this.$route.query.diy)
      _this.$setEncryptBy(_this.params,"_STATUSDATA")
      _this.statusData = JSON.parse(_this.params)
      return
    }
    if(_this.$base.isNull(_this.$route.query)){
      _this.loading = false
      _this.goIndex('home')
      return
    }

  }

  async mounted(){
    const _this:any = this;
    console.log('%c [ ----- ]: ', 'color: #bf2c9f; background: pink; font-size: 13px;', 'x')
    window.scrollTo({top:0})
    console.log(_this.$route.query);
    console.log(_this.$decryptBy(this.$route.query.p));
    console.log('%c [ 666 ]: ', 'color: #bf2c9f; background: pink; font-size: 13px;', _this.$route.query.p)
    console.log(_this.shoppingCartList);
    
    if(!_this.$base.isNull(_this.$route.query.p)){
      _this.params = JSON.parse(_this.$decryptBy(_this.$route.query.p))
      if(_this.params.noLogin){
        const res = await AddCartApi( {..._this.params,isBuyNow: 0});
        console.log('%c [res]: ', 'color: #bf2c9f; background: pink; font-size: 13px;', res)
        _this.params={...res.data,specsIds:_this.params.specsIds,unitIds:_this.params.unitIds};

        console.log('%c [  ]: ', 'color: #bf2c9f; background: pink; font-size: 13px;', _this.params)
        _this.AddCartFn(_this.params)
      }else{
        _this.AddCartFn(_this.params)
      }
      
      console.log('%c [ 调购物车接口 ]: ', 'color: #bf2c9f; background: pink; font-size: 13px;', '调购物车接口')
      
      return
    }
  }

  destroyed(){
    const _this:any = this;
    localStorage.removeItem('_STATUSDATA')
    localStorage.removeItem('_DIYIMGS')
  }

  /**
   * @Author HS
   * @Date 2021/7/21 5:35 下午
   * @Description: 浏览器回退
   * @Params: null
   * @Return: null
   */
  cancel () {
    this.$router.go(-1);
  }

  /**
   * @Author HS
   * @Date 2021/8/9 4:10 下午
   * @Description: 获取预排单发货时间
   * @Params: { string ： id - true } [购物车Id]
   * @Return: null
  */
  async GetPreTimeByCartFn(id:string){
    const _this:any = this;
    let params:GetPreTimeByCartReq = {
      unitIds:_this.params.unitIds,
      specsIds:_this.params.specsIds,
    }
    const res = await GetPreTimeByCartApi(params)
    _this.loading = false
    // let a = setTimeout(()=>{_this.loading = false;clearTimeout(a)},0)
    const { code, data } = res
    if(code != 200 ){
      _this.preTimeByCart = "获取失败"
    }else{
      _this.preTimeByCart = data
    }
    let statusData = {
      id:id,
      time:_this.preTimeByCart
    }
    _this.$setEncryptBy(JSON.stringify(statusData),"_STATUSDATA")
    console.log('%c [ 333 ]: ', 'color: #bf2c9f; background: pink; font-size: 13px;', '333')
    console.log(_this.shoppingCartList);
  }


  /**
   * @Author HS
   * @Date 2021/7/21 5:32 下午
   * @Description: 成品加入购物车
   * @Params: null
   * @Return: null
  */
  async AddCartFn(data:any){
    console.log(data);
    const _this:any = this;
    _this.loading = true

    // let params:AddCartReq = _this.params
    // params.isBuyNow = 0
    // const res = await AddCartApi(params)
    // const { code, data, msg } = res
    // if(code != 200 ){
    //   _this.loading = false;
    //   _this.data = data
    //   console.log('%c [ ------- ]: ', 'color: #bf2c9f; background: pink; font-size: 13px;', '-------4')
    //   console.log(_this.data)
    //   _this.data.msg = msg
    //   _this.addType = 2
    //   _this.preTimeByCart = "获取失败"
    //   let statusData = {
    //     data:JSON.stringify(data),
    //     time:"获取失败"
    //   }
    //   _this.$setEncryptBy(JSON.stringify(statusData),"_STATUSDATA")
    //   return
    // }
    _this.addType = 1
    let list = _this.shoppingCartList.filter((item:any) => item.cartId == data.cartId)
    if(list.length <= 0){
      _this.$store.commit('UNSHIFT_LIST',JSON.stringify(data))
    }
    else{
      let index = _this.shoppingCartList.findIndex((item:any) => item.cartId == data.cartId)
      _this.shoppingCartList[index] = data
      _this.$store.commit('SET_LIST',JSON.stringify(_this.shoppingCartList))
    }
    _this.data = data
    console.log('%c [ ----啊啊啊啊啊啊--- ]: ', 'color: #bf2c9f; background: pink; font-size: 13px;', '-------1')
    console.log(_this.data)
    _this.GetPreTimeByCartFn(data.cartId)

  }


  /**
   * @Author HS
   * @Date 2021/7/2 3:59 下午
   * @Description: Index页面跳转
   * @Params: { string ： path - true } [path：路由]
   * @Return: null
   */
  goIndex(path:string){
    const _this:any = this;
    console.log('%c [  ]: ', 'color: #bf2c9f; background: pink; font-size: 13px;', path)
    _this.$base.goRouter(_this.$IndexConfig[path].path);
    _this.$store.dispatch('GetMyCartsFn',_this)
  }


}
